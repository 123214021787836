<template>
  <div
    class="modal fade modal-xl"
    tabindex="-10"
    id="modal_view_invito_payment_status"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <!-- <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :z-index="1100"
        ></loading> -->

      <div v-if="(id_invitation && loaded_invitation) || !id_invitation">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Visualizza status pagamenti</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="modal_view_invito_payment_status_closex"
              @click="closeModal"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <div class="ps-5 pe-5 repeater-form bg-even">
                <div class="row pb-4 border-top border-secondary">
                  <div class="col-4 my-3"><b>Sessione</b></div>
                  <template v-if="isIntegration">
                    <div class="col-4 my-3"><b>Situazione precedente</b></div>
                    <div class="col-4 my-3"><b>Situazione attuale</b></div>
                  </template>
                  <template v-else>
                    <div class="col-8 my-3"></div>
                  </template>
                </div>
              </div>
              <div
                class="ps-5 pe-5 repeater-form"
                v-for="(invitation, index) in invitation_results"
                :key="invitation.id_session"
                :class="index % 2 ? 'bg-even' : 'bg-odd'"
              >
                <div
                  class="row pb-4 border-top border-secondary"
                  :class="
                    index == invitation_results.length - 1
                      ? 'border-bottom'
                      : ''
                  "
                >
                  <div class="col-4 mt-2">
                    <b>{{ invitation.description }}</b>
                  </div>
                  <template v-if="isIntegration">
                    <div class="col-4 mt-2">
                      <div
                        v-for="(invitato, y) in invitation.payments"
                        :key="y"
                        class="border-secondary"
                        :class="
                          y < invitation.payments.length - 1
                            ? 'border-bottom'
                            : ''
                        "
                      >
                        <div class="row">
                          <p>
                            {{ invitato.surname }} {{ invitato.name }}
                            {{ invitato.date_of_birth }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 mt-2">
                      <div
                        v-for="(invitato, y) in invitation.invitation"
                        :key="y"
                        class="border-secondary"
                        :class="
                          y < invitation.invitation.length - 1
                            ? 'border-bottom'
                            : ''
                        "
                      >
                        <div class="row">
                          <p>
                            {{ invitato.surname }} {{ invitato.name }}
                            {{ invitato.date_of_birth }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-4 mt-2">
                      <div
                        v-for="(invitato, y) in invitation.payments"
                        :key="y"
                        class="border-secondary"
                        :class="
                          y < invitation.payments.length - 1
                            ? 'border-bottom'
                            : ''
                        "
                      >
                        <div class="row">
                          <p>
                            {{ invitato.surname }} {{ invitato.name }}
                            {{ invitato.date_of_birth }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="modal_view_invito_payment_status_close_button"
              @click="closeModal"
            >
              Chiudi
            </button>
            <button
              v-if="!payed"
              @click="payInvite"
              class="badge bg-light-success text-success rounded blueFit fs-6"
            >
              Conferma pagamento
            </button>
          </div>
          <!-- <Loader v-else /> -->
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import { callFunctionAfterAlert, alertFailed } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
// import EtichettaGruppo from "./EtichettaGruppo.vue";
export default {
  name: "add-persona-modal",
  components: {
    // Field,
    // ErrorMessage,
    // Form,
    // EtichettaGruppo,
  },
  props: {
    selected_invitation: {
      type: Object,
    },
    id_invitation: {
      type: Number,
    },
  },
  emits: ["getList", "closeModal"],
  setup(props, { emit }) {
    const view_errors = ref(false);
    const disableButton = ref(false);
    const onInvalidSubmit = (v) => {
      
      view_errors.value = true;
      alertFailed("Inserire i campi obbligatori");
    };
    const onSubmit = async (values, { resetForm }) => {
      await callFunctionAfterAlert(
        props.id_invitation ? API.GROUPS_EDIT : API.GROUPS_ADD,
        { ...values, id_group: props.id_group },
        `Sei sicuro di voler ${
          props.id_group ? "modificare" : "creare"
        } il gruppo <br> <b>${values.name}</b>?`,
        `${
          props.id_group
            ? "Gruppo modificato con successo"
            : "Gruppo creato con successo"
        }`,
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedGroup) => {
        if (!props.id_group) {
          resetForm();
        }

        if (isAddedorModifiedGroup) {
          document
            .getElementById("modal_view_invito_payment_status_close_button")
            .click();
          emit("getList");
          setTimeout(() => {
            resetForm();
          }, 500);
        }
      });
    };
    const schema = yup.object().shape({
      name: yup.string().required("Obbligatorio"),
      color: yup.string().required("Obbligatorio"),
      max_sessions: yup
        .number()
        .typeError("Inserire un numero")
        .nullable()
        .required("Obbligatorio"),
      can_invitation_send: yup.boolean(),
      max_session_invitation_send: yup
        .number()
        .nullable()
        .when(["can_invitation_send"], {
          is: (can_invitation_send) => {
            return can_invitation_send;
          },
          then: (schema) =>
            schema.required("Obbligatorio").typeError("Inserire un numero"),
        }),
      max_guest_sessions: yup
        .number()
        .nullable()
        .when(["can_invitation_send"], {
          is: (can_invitation_send) => {
            return can_invitation_send;
          },
          then: (schema) =>
            schema.required("Obbligatorio").typeError("Inserire un numero"),
        }),
      max_invitation_send: yup
        .number()
        .nullable()
        .when(["can_invitation_send"], {
          is: (can_invitation_send) => {
            return can_invitation_send;
          },
          then: (schema) =>
            schema.required("Obbligatorio").typeError("Inserire un numero"),
        }),
    });

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ll|gg" }
    );

    get_lookups();

    const closeModal = () => {
      // setTimeout(() => {
      emit("closeModal");
      // }, 1000);
    };

    const payload = ref({
      id_invitation: props.id_invitation,
    });

    const {
      element: invitation,
      loaded: loaded_invitation,
      getElement: get_invitation,
      status: status_invitation,
    } = useApiCall(API.TICKETING_INVITATION_VIEW, payload);

    watch(
      () => props.id_invitation,
      async (val) => {
        if (val) {
          
          payload.value = {
            id_invitation: val,
          };
          await get_invitation();
          formValues.value = { ...invitation.value.results[0] };
        } else {
          formValues.value = {};
        }
      }
    );

    const formValues = ref({});

    const payInvite = async () => {
      
      await callFunctionAfterAlert(
        API.TIKCETING_PAYMENT_INVITATION_CONFIRM,
        { id_invitation: props.id_invitation },
        `Sei sicuro di voler rendere l'invito pagato?`,
        `${
          props.id_group
            ? "Pagamento confermato con successo"
            : "Pagamento confermato con successo"
        }`,
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isConfirmed) => {
        if (isConfirmed) {
          document
            .getElementById("modal_view_invito_payment_status_close_button")
            .click();
          emit("getList");
        }
      });
    };
    return {
      payInvite,
      invitation,
      loaded_invitation,
      formValues,
      lookups,
      schema,
      onSubmit,
      onInvalidSubmit,
      disableButton,
      view_errors,
      closeModal,
    };
  },
  computed: {
    payed() {
      return this.invitation ? this.invitation.results[0].payed : null;
    },
    invitation_results() {
      return this.invitation ? this.invitation.results[0].sessions : null;
    },
    isIntegration() {
      return this.invitation ? this.invitation.results[0].integration : null;
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
.bg-even {
  background-color: rgb(248, 250, 251);
}
.bg-odd {
  background-color: rgb(255, 255, 255);
}
</style>
