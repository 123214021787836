<template>
  <form autocomplete="off" onsubmit="event.preventDefault();">
    <div class="position-relative">
      <div class="search_field" style="max-height: 1rem">
        <input
          class="form-control"
          type="text"
          v-model="input"
          id="inputList"
          @input="
            isFocusInput = true;
            $emit('inputElement', $event.target.value);
          "
          @focus="isFocusInput = true"
          @keydown.delete="list = []"
          :disabled="disabled"
          :placeholder="placeholder"
          :readonly="readonly"
        />
        <img
          class="dp__icon dp__clear_icon dp__input_icons"
          name="cancellaLabel"
          src="/media/SVG/deleteInput.svg"
          @click="
            disabled ? '' : (input = '');

            disabled
              ? ''
              : emitEventOnCancel
              ? $emit('isSelectedElement', '')
              : '';
            $emit('cancelElement', '');
          "
          :id="id_bottone_chiudi"
        />
      </div>

      <!-- $emit('selectedelement', {
          value: element.id,
          name: `${element.cognome.trim()},${element.nome.trim()} ${
            element.data_nascita
          }`,
        }) -->
      <ul
        class="list"
        :style="`top: 1.7rem;`"
        v-if="list.length && isFocusInput && !disable_list"
      >
        <li
          class="text-hover-primary"
          style="cursor: pointer"
          v-for="element in list"
          :key="element.id"
          :value="element.id"
          @click.prevent="
            input = element.denominazione
              ? element.denominazione
              : parameterToShow === 'nome'
              ? `${element.surname.trim()}, ${element.name.trim()} - ${
                  element.date_of_birth ? element.date_of_birth.trim() : ''
                }`
              : parameterToShow === 'servizioEsterno'
              ? `${element.cognome.trim()}, ${element.nome.trim()} - ${format(
                  new Date(element.data_nascita.trim())
                )} - ${element.ProvinciaResidenza.trim()}`
              : element[parameterToShow].trim();
            getEntireRecord
              ? $emit('isSelectedElement', element)
              : $emit('isSelectedElement', element.id);
          "
          @touchstart.prevent="$emit('isSelectedElement', element.id)"
        >
          <a>{{
            parameterToShow === "nome"
              ? `${element.surname.trim()}, ${element.name.trim()} - ${
                  element.date_of_birth ? element.date_of_birth.trim() : ""
                } ${
                  element.cod_tessera ? " - " + element.cod_tessera.trim() : ""
                }`
              : parameterToShow == "servizioEsterno"
              ? `${element.cognome.trim()}, ${element.nome.trim()} - ${format(
                  element.data_nascita
                    ? new Date(element.data_nascita.trim())
                    : ""
                )} ${
                  element.ProvinciaResidenza
                    ? " - " + element.ProvinciaResidenza.trim()
                    : ""
                }`
              : element[parameterToShow].trim()
          }}</a>
        </li>
        <!-- <li class="pt-2" v-show="!no_new">
        <a
          href=""
          data-bs-toggle="modal"
          data-bs-target="#modal_add-anagrafica-persona"
          >Nuova anagrafica</a
        >
      </li> -->
      </ul>
    </div>
  </form>
</template>

<script>
import { computed, ref, watch } from "vue";
import { requestAppPost } from "@/db/dbManag";
import useDetectOutsideClick from "@/composables/composableOutsideClick";
import { verificaFormatoData, format } from "@/functions/formatDate";
export default {
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    payloadParameterTextName: {
      type: String,
      required: true,
    },
    payloadParameterTextNameSec: {
      type: String,
    },
    payloadParameterTextNameTer: {
      type: String,
    },
    parameterToShow: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    value: {
      type: Text,
    },
    placeholder: {
      type: String,
    },
    getEntireRecord: {
      type: Boolean,
      default: false,
    },
    emitEventOnCancel: {
      type: Boolean,
      default: true,
    },
    payloadOtherParameterObject: {
      type: Object,
    },
    readonly: {
      type: Boolean,
    },
    apiServizioEsterno: {
      type: Boolean,
    },
    id_bottone_chiudi: {
      type: Boolean,
    },
    disable_list: {
      type: Boolean,
      default: false,
    },
    noApi: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["isSelectedElement", "inputElement", "elementList", "cancelElement"],
  setup(props, { emit }) {
    // da vedere data nascita. deve riempirsi solo quando la date è completa
    const input = ref(props.value ? props.value : "");
    const list = ref([]);
    const firstParameter = computed(() => input.value.split(",")[0]);
    const secondParameter = computed(() => {
      const arraySplitted = input.value.split(",");
      const dataNascita = arraySplitted[1] ? arraySplitted[1].split("-") : [];
      return dataNascita[0] ? dataNascita[0].trim() : null;
    });
    const thirdParameter = computed(() => {
      const arraySplitted = input.value.split(",");
      const dataNascita = arraySplitted[1] ? arraySplitted[1].split("-") : [];
      return dataNascita[1] ? verificaFormatoData(dataNascita[1].trim()) : null;
    });
    const getList = async () => {
      await requestAppPost(
        props.apiLink,
        props.payloadParameterTextNameSec && !props.payloadParameterTextNameTer
          ? {
              ...props.payloadOtherParameterObject,
              [props.payloadParameterTextName]: firstParameter.value,
              [props.payloadParameterTextNameSec]: secondParameter.value,
            }
          : props.payloadParameterTextNameSec &&
            props.payloadParameterTextNameTer
          ? {
              ...props.payloadOtherParameterObject,
              [props.payloadParameterTextName]: firstParameter.value,
              [props.payloadParameterTextNameSec]: secondParameter.value,
              [props.payloadParameterTextNameTer]: thirdParameter.value,
            }
          : {
              ...props.payloadOtherParameterObject,
              [props.payloadParameterTextName]: firstParameter.value,
            }
      ).then((res) => {
        if (!props.apiServizioEsterno) {
          list.value = [];
          list.value = res.data.results;
          emit("elementList", res.data.results);
        } else {
          list.value = [];
          list.value = res.data[1].Data;
        }
      });
    };
    watch(input, async (newVal) => {
      if (newVal.length >= 3 && !props.noApi) {
        await getList();
      }
    });
    watch(
      () => props.value,
      async (newVal) => {
        if (!newVal) {
          input.value = "";
        }
      }
    );
    const inputElement = ref();
    const isFocusInput = ref(false);

    useDetectOutsideClick(inputElement, () => {
      isFocusInput.value = false;
    });
    // onMounted(() => {
    //   // Assegnare l'elemento DOM referenziato a myInputRef
    //   inputElement.value = document.getElementById("inputList");
    // });
    // onUpdated(() => {
    //   inputElement.value = document.getElementById("inputList");
    // });
    return { input, list, inputElement, isFocusInput, format };
  },
};
</script>

<style scoped>
.list {
  position: absolute;
  z-index: 999;
  background-color: #f3f6f8;
  border: 1px solid #a3a7b8;
  border-radius: 0.475rem;
  list-style-type: none;
  width: 100%;
  padding-top: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
  max-height: 9rem;
  overflow: scroll;
}

.dp__clear_icon,
.dp__input_icon {
  position: absolute;
  transform: translateY(-25%);
  color: var(--dp-icon-color);
}
</style>
