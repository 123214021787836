<template>
  <div>
    <div class="card shadow mb-7">
      <div class="card-body pt-6 pb-0">
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
          >
            <li class="nav-item">
              <span
                style="cursor: pointer"
                aria-current="page"
                href="/societa/dettaglio-societa/anagrafica-societa/8625"
                class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                :class="payload.id_payment_status == 0 ? 'active' : ''"
                @click="
                  payload.id_payment_status = 0;
                  payload.rowstoskip = 0;
                  id_year && id_event ? get_ticketing_and_set_checked() : '';
                "
              >
                Non pagato
              </span>
            </li>
            <li class="nav-item">
              <span
                style="cursor: pointer"
                aria-current="page"
                href="/societa/dettaglio-societa/anagrafica-societa/8625"
                class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                :class="payload.id_payment_status == 1 ? 'active' : ''"
                @click="
                  payload.id_payment_status = 1;
                  payload.rowstoskip = 0;
                  id_year && id_event ? get_ticketing_and_set_checked() : '';
                "
              >
                Pagato
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <FiltersTicketing
      @getList="get_ticketing_and_set_checked"
      @resetFilters="resetFilters"
      :loaded="loaded_ticketing_and_checked"
      @setPayload="setPayload"
      :payload="payload"
      :pressedSearch="pressedSearch"
      @pressedSearch="pressedSearch = true"
    />
    <TableTicketing
      @getList="get_ticketing_and_set_checked"
      @resetFilters="resetFilters"
      :objectList="ticketing"
      :loaded="loaded_ticketing_and_checked"
      :status="status_ticketing"
      @setPayload="setPayload"
      :payload="payload"
      :pressedSearch="pressedSearch"
      :id_event="payload.id_event"
      :id_year="payload.id_year"
      @checkedElement="$emit('checkedElement', $event)"
    />
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/functions/trimInput";
import FiltersTicketing from "../components/ticketing/FiltersTicketing.vue";
import TableTicketing from "../components/ticketing/TableTicketing.vue";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
import { useStore } from "vuex";

export default defineComponent({
  name: "ticketing",
  components: {
    FiltersTicketing,
    TableTicketing,
  },
  emits: ["listLength"],
  setup(props, { emit }) {
    const pressedSearch = ref(false);
    const store = useStore();
    const id_year = computed(() => store.getters.id_year);
    const id_event = computed(() => store.getters.id_event);
    const payload = ref({
      id_person: 0,
      id_payment_status: 0,
      name: "",
      surname: "",
      sortcolumn: "surname",
      sortorder: "asc",
      id_invitation: null,
      id_event: id_event.value,
      id_year: id_year.value,
      rowstoskip: 0,
      fetchrows: 25,
    });
    const setPayload = ({ name, value }) => {
      payload.value[name] = value;
    };

    const {
      element: ticketing,
      loaded: loaded_ticketing,
      getElement: get_ticketing,
      status: status_ticketing,
    } = useApiCall(API.TICKETING_INVITATION_LIST, payload);

    onMounted(async () => {
      if (!props.from_invitations) {
        setCurrentPageBreadcrumbs("Biglietteria", payload);
      }
      if (payload.value.id_year && payload.value.id_event)
        await get_ticketing_and_set_checked();
    });
    watch([id_year, id_event], async () => {
      payload.value.id_year = id_year.value;
      payload.value.id_event = id_event.value;
      if (id_year.value && id_event.value) {
        await get_ticketing_and_set_checked();
      }
    });

    const resetFilters = () => {
      payload.value = {
        id_person: 0,
        id_payment_status: null,
        name: "",
        surname: "",
        id_event: id_event.value,
        id_year: id_year.value,
        id_invitation: null,
        sortcolumn: "surname",
        sortorder: "asc",
        rowstoskip: 0,
        fetchrows: 25,
      };
      get_ticketing_and_set_checked();
    };
    const checked_setted = ref(false);
    const get_ticketing_and_set_checked = async () => {
      await get_ticketing();
      checked_setted.value = false;
      emit("listLength", ticketing.value.results.length);
      checked_setted.value = true;
    };
    return {
      checked_setted,
      get_ticketing_and_set_checked,
      resetFilters,
      setPayload,

      ticketing,
      loaded_ticketing,
      status_ticketing,
      trimInput,
      payload,
      pressedSearch,
      id_year,
      id_event,
    };
  },
  computed: {
    loaded_ticketing_and_checked() {
      return this.loaded_ticketing && this.checked_setted;
    },
  },
});
</script>
